@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    outline: none;
}

/* hero section start */

.hero-section{
    background-image: url(../../../../public/assets/images/hero.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 90vh;
}

.hero-section .hero-container{
    height: 100%;
    text-align: center;
    padding: 0px 40px 40px 40px;
}

.hero-section .hero-container img{
    width: 100%;
    max-width: 380px;
    height: auto;
}

.hero-section .hero-container h1{
    font-size: 2.2rem;
    color: #fff;
}

.hero-section .hero-container p{
    font-size: 1.4rem;
    color: #fff;
}

.hero-section .hero-container .registration-button{
    text-transform: uppercase;
    border-radius: 1rem;
    text-decoration: none;
    font-size: 1rem;
    color: white;
    font-weight: 700;
    background: #003d7e;
    padding: 1em 2.1em 1.1em;
    transition: 0.3s;
    cursor: pointer;
}

.hero-section .hero-container .registration-button:hover{
    background: #001435;
}

@media(max-width:848px){
    .hero-section .hero-container h1{
        font-size: 2rem;
    }
    .hero-section .hero-container p{
        font-size: 1.2rem;
    }
}

@media(max-width:678px){
    .hero-section .hero-container h1{
        font-size: 1.8rem;
    }
    .hero-section .hero-container p{
        font-size: 1rem;
    }
}

/* hero section end */

/* about section start */

.about-section .about-container h1{
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    position: relative;
    margin-bottom: 60px;
    margin-top: 2rem;
}

.about-section .about-container h1:after{
    content: '';
    position: absolute;
    width: 100px;
    height: 4px;
    background-color: #003d7e;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.about-section{
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-image: url(../../../../public/assets/images/tes4.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
}

.about-container{
    width: 80%;
    background: #fff;
    padding: 30px 60px 40px 40px;
    border-radius: 5rem;
}

.about-section .about-container .content{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.about-section .about-container .content .left-side{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.about-section .about-container .content .left-side img{
    width: 100%;
    max-width: 500px;
    height: auto;
}

.about-section .about-container .content .right-side{
    width: 75%;
    margin-left: 75px;
}

.about-section .about-container .content .right-side h2{
    font-size: 2rem;
}

.about-section .about-container .content .right-side p{
    font-size: 1.1rem;
}

@media(max-width:820px){
    .about-container{
        width: 100%;
        margin: 40px 0;
        height: 100%;
    }
    .about-section .about-container .content{
       flex-direction: column;
    }
    .about-section .about-container .content .left-side{
        width: 100%;
        flex-direction: row;
        margin-top: 40px;
        justify-content: center;
        flex-wrap: wrap;
    }
    .about-section .about-container .content .right-side{
        width: 100%;
        margin-left: 0;
    }
    .about-section .about-container h2{
        text-align: center;
    }
    .about-section .about-container p{
        text-align: center;
    }
}

@media(max-width:848px){
    .about-section .about-container h1{
        font-size: 2rem;
    }
    .about-section .about-container p{
        font-size: 1.2rem;
    }
}

@media(max-width:678px){
    .about-section .about-container h2{
        font-size: 1.8rem;
        text-align: center;
    }
    .about-section .about-container p{
        font-size: 1rem;
        text-align: center;
    }
}

/* about section end */

/* category section start */

.category-section{
    width: 100%;
    display: grid;
    place-items: center;    
    background: linear-gradient(90deg, deepskyblue, #003d7e);
    min-height: 80vh;
    /* min-height: 100vh;
    width: 100%;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center; */
}

/* .category-container{
    
    width: 80%;
    background: #fff;
    padding: 30px 60px 40px 40px; 
} */

.category-section .category-container h1{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    position: relative;
    color: white;
    /* margin-top: 2rem; */
}

.category-section .category-container h1::after{
    content: '';
    position: absolute;
    width: 100px;
    height: 4px;
    background-color: #003d7e;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.category-section .category-container .category-row{
    display: flex;
    flex-wrap: wrap;
}

.category-section .category-container .category-column{
    width: 100%;
    padding: 0 1em 1em 1em;
    text-align: center;
}

.category-section .category-container .category-card{
    width: 100%;
    height: 100%;
    padding: 2em 1.5em;
    background: linear-gradient(#ffffff 50%, #003d7e 50%);
    background-size: 100% 200%;
    background-position: 0 2.5%;
    border-radius: 5px;
    /* box-shadow: 0 0 35px rgba(0, 0, 0, 0.12); */
    cursor: pointer;
    transition: 0.5s;
}

.category-section .category-container .category-card h3{
    font-size: 20px;
    font-weight: 600;
    color: #1f194c;
    margin: 1em 0;
}

.category-section .category-container .category-card p{
    color: #575a7b;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
}

.category-section .category-container .icon-wrapper{
    background-color: #003d7e;
    position: relative;
    margin: auto;
    font-size: 30px;
    height: 2.5em;
    width: 2.5em;
    color: #ffffff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: 0.5s;
}

.category-section .category-container .category-card:hover{
    background-position: 0 100%;
}

.category-section .category-container .category-card:hover .icon-wrapper{
    background-color: #fff;
    color: #003d7e;
}

.category-section .category-container .category-card:hover h3{
    color: #fff;
}

.category-section .category-container .category-card:hover p{
    color: #f0f0f0;

}

@media screen and (min-width:768px) {
    .category-section{
        padding: 0 2em;
    }
    .category-section .category-container .category-column{
        flex: 0 50%;
        max-width: 50%;
    }
}
/* 
@media screen and (min-width:992px){
    .category-section{
        padding: 1em 3em;
    }
    .category-section .category-container .category-column{
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
} */

/* category section end */

/* after event start */

.after-event-section{
    min-height: 100vh;
    background: #fff;
    padding: 5rem 9% 5rem;
}

.after-event-row{
    /* display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
}

/* @media (max-width: 768px){
    .after-event-row{
        flex-direction: column;
    }
} */

.after-event-section .after-event-heading h1{
    text-align: center;
}

.after-event-section .after-event-heading h1{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.after-event-section .after-event-heading h1:after{
    content: '';
    position: absolute;
    width: 100px;
    height: 4px;
    background-color: #003d7e;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.after-event-section p{
    text-align: justify;
    font-size: 1rem;
    align-items: center;
}


/* after event end */

/* contact start */

.contact-section .container1{
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 2rem;
    background: linear-gradient(90deg, #003d7e, deepskyblue);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
}

.form{
    width: 100%;
    max-width: 820px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.contact-form{
    background-color: #003d7e;
    position: relative;
}

.circle{
    border-radius: 50%;
    /* background: linear-gradient(135deg, transparent 20%, #149279); */
    position: absolute;
}

.circle.one{
    width: 130px;
    height: 130px;
    top: 130px;
    right: -40px;
}

.circle.two{
    width: 80px;
    height: 80px;
    top: 10px;
    right: 30px;
}

.contact-form::before{
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: #003d7e;
    transform: rotate(45deg);
    top: 50px;
    left: -13px;
}

form{
    padding: 2.3rem 2.2rem;
    z-index: 10;
    overflow: hidden;
    position: relative;
}

.title{
    color: #fff;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 0.7rem;
}

.input-container{
    position: relative;
    margin: 1rem 0;
}

.input{
    width: 100%;
    outline: none;
    border: 2px solid #fafafa;
    background: none;
    padding: 0.6rem 1.2rem;
    color: #fff;
    font-weight: 500;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    border-radius: 25px;
    transition: 0.3s;
}

textarea.input{
    padding: 0.8rem 1.2rem;
    min-height: 150px;
    border-radius: 22px;
    resize: none;
    overflow-y: auto;
}

.input-container label{
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    padding: 0 0.4rem;
    color: #fafafa;
    font-size: 0.9rem;
    font-weight: 400;
    pointer-events: none;
    z-index: 1000;
    transition: 0.5s;
}

.input-container.textarea label{
    top: 1rem;
    transform: translateY(0);
}

.btn{
    padding: 0.6rem 1.3rem;
    background-color: #fff;
    border: 2px solid #fafafa;
    font-size: 0.95rem;
    color: #003d7e;
    line-height: 1;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
}

.btn:hover{
    background-color: transparent;
    color: #fff;
}

.input-container span{
    position: absolute;
    top: 0;
    left: 25px;
    transform: translateY(-50%);
    font-size: 0.8rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none;
    z-index: 500;
}


.input-container span:before,
.input-container span::after{
    content: "";
    position: absolute;
    width: 10%;
    opacity: 0;
    transition: 0.3s;
    height: 5px;
    background-color: #003d7e;
    top: 50%;
    transform: translateY(-50%);
}

.input-container span:before{
    left: 50%;
}

.input-container span::after{
    right: 50%;
}

.input-container.focus label{
    top: 0;
    transform: translateY(-50%);
    left: 25px;
    font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after{
    width: 50%;
    opacity: 1;
}

.contact-info{
    padding: 2.3rem 2.2rem;
    position: relative;
}

.contact-info .title{
    color: #003d7e;
}

.text{
    color: #333;
    margin: 1.5rem 0 2rem 0;
}

.information {
    display: flex;
    color: #555;
    margin: 0.7rem 0;
    align-items: center;
    font-size: 0.95rem;
}

.information a{
    text-decoration: none;
    color: #555;
    transition: ease-out;
}

.information a:hover{
    color: #000;
}

.contact-icon{
    width: 28px;
    margin-right: 0.7rem;
}

.social-media{
    padding: 2rem 0 0 0;
}

.social-media p{
    color: #333;
}

.social-icons{
    display: flex;
    margin-top: 0.5rem;
}

.social-icons a{
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: #003d7e;
    color: #fff;
    text-align: center;
    line-height: 35px;
    margin-right: 0.5rem;
    transition: .3s;
}

.social-icons a:hover{
    transform: scale(1.05);
}

/* .contact-info:before{
    content: "";
    position: absolute;
    width: 110px;
    height: 100px;
    border: 22px solid #1abc9c;
    border-radius: 50%;
    bottom: -77px;
    right: 50px;
    opacity: 0.3;
} */

.big-circle{
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: linear-gradient(to bottom, #1cd4af, #159b80);
    bottom: 50%;
    right: 50%;
    transform: translate(-40%, 38%);
}

.big-circle::after{
    content: "";
    position: absolute;
    width: 360px;
    height: 360px;
    background-color: #fafafa;
    border-radius: 50%;
    top: calc(50% - 180px);
    left: calc(50% - 180px);
}

.square{
    position: absolute;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(181%, 11%);
    opacity: 0.2;
}

@media (max-width: 850px){
    .form{
        grid-template-columns: 1fr;
    }
    .contact-info:before{
        bottom: initial;
        top: -75px;
        right: 65px;
        transform: scale(0.95);
    }
    .contact-form:before{
        top: -13px;
        left: initial;
        right: 70px;
    }
    .square{
        transform: translate(140%, 43%);
        height: 350px;
    }
    .big-circle{
        bottom: 75%;
        transform: scale(0.9) translate(-40%, 30%);
        right: 50%;
    }
    .text{
        margin: 1rem 0 1.5rem 0;
    }
    .social-media{
        padding: 1.5rem 0 0 0;
    }
}

@media (max-width:480px){
    .container1{
        padding: 1.5rem;
    }
    .contact-info:before{
        display: none;
    }
    .square,
    .big-circle{
        display: none;
    }
    form,
    .contact-info{
        padding: 1.7rem 1.6rem;
    }
    .text,
    .information,
    .social-media p{
        font-size: 0.8rem;
    }
    .title{
        font-size: 1.15rem;
    }
    .social-icons a{
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
    .contact-icon {
        width: 23px;
    }
    .input{
        padding: 0.45rem 1.2rem;
    }
    .btn{
        padding: 0.45rem 1.2rem;
    }
}


/* contact end */

/* logo start */

.brand-logo-section{
    width: 100%;
    display: grid;
    place-items: center;
    background: #f2f2f2;
}

.brand-logo-section .brand-logo-container h1{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.brand-logo-section .brand-logo-container h1:after{
    content: '';
    position: absolute;
    width: 100px;
    height: 4px;
    background-color: #003d7e;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.brand-logo-section .brand-logo-container{
    width: 90%;
    height: auto;
    text-align: center;
}

.brand-logo-section .brand-logo{
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
}

.brand-logo-section .brand-logo ul{
    padding: 0;
    margin: 0;
}

.brand-logo-section .brand-logo ul li{
    list-style: none;
    display: inline-block;
    height: 100px;
    cursor: pointer;
    transition: .5s;
}

.brand-logo-section .brand-logo ul li img{
    width: 100%;
    height: 100%;
}

/* logo end */

/* newsletter start */


button:focus, input:focus, textarea:focus {
	outline: 0;
}

.newsletter_wrap {
	width: 100%;
}

.newsletter_item {
    background: linear-gradient(90deg, deepskyblue,#003d7e);
	/* background-image: url(../img/newsletter_bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center; */
	padding: 60px 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.newsletter_item h2 {
	font-size: 35px;
	font-weight: 700;
	margin-bottom: 10px;
	color: #ffffff;
}

.newsletter_item p {
	font-size: 15px;
	line-height: 24px;
	color: #ffffff;
}

.input_box {
	width: 280px;
	height: 50px;
	background: hsla(0,0%,100%,.03);
    border-color: hsla(0,0%,100%,.1);
    position: relative;
    border-radius: 10px;
}

.input_box input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: transparent;
	border: transparent;
	padding-left: 20px;
	color: #fff;
}

.input_box input::placeholder {
	color: #fff;
}

.input_box button {
	position: absolute;
	right: 0;
	top: 0;
	padding: 0 30px;
	border-radius: 10px;
	height: 50px;
	border: transparent;
	color: #ffffff;
	font-weight: 700;
	background: deepskyblue;
	cursor: pointer;
}



/*Extra large devices (large desktops, 1200px and up)*/
@media (max-width: 1399px){
	
}
@media (min-width: 1400px) { 
	
}
@media (min-width: 1200px) { 
	.container {
		max-width: 1230px;
	}
}

@media (max-width: 1199px) { 
	
}

@media (max-width: 991px) {
	.newsletter_item {
		flex-direction: column;
		text-align: center;
	}

	.input_box {
		margin-top: 30px;
	}

	.newsletter_item p br {
		display: none;
	}
}

/*Medium devices (tablets, 767px and down)*/
@media (max-width: 767px) {
	
}

/*Small devices (landscape phones, 575px and down)*/
@media (max-width: 575px) {
	
}

/* newsletter end */

/* footer start */



/* footer end */